import React from 'react';
import * as styles from './CookiePolicy.module.scss';
import Container from '../ui/Container';
import Headline from 'components/Headline';
import TitleSection from '../ui/TitleSection';

const CookiePolicy: React.FC = () => {
  return (
    <Container section className={styles.cookiePolicyContainer}>
      <TitleSection title="&nbsp;" subtitle="Politică de confidențialitate și cookies" noSeparator className="mb-16" />
      <p>
        Acest website folosește cookie-uri proprii și de la terți pentru a furniza vizitatorilor o experiență mult mai
        bună de navigare și servicii adaptate nevoilor și interesului fiecăruia.
      </p>
      <p>
        Cookie-urile joacă un rol important în facilitarea accesului și livrării multiplelor servicii de care
        utilizatorul se bucură pe Internet, cum ar fi:
      </p>
      <ul>
        <li>
          Personalizarea anumitor setări precum: limba în care este vizualizat un site, moneda în care se exprimă
          anumite prețuri sau tarife, păstrarea opțiunilor pentru diverse produse (măsuri, alte detalii, etc.) în coșul
          de cumpărături (și memorarea acestor opțiuni) – generându-se astfel flexibilitatea „coșului de cumpărături”
          (accesarea preferințelor vechi prin accesarea butonului „înainte” și „înapoi”).
        </li>
        <li>
          Cookie-urile oferă deținătorilor de site-uri un feedback valoros asupra modului în care sunt utilizate
          site-urile lor de către utilizatori, astfel încât să le poată face mai eficiente și mai accesibile pentru
          utilizatori.
        </li>
        <li>
          Permit aplicațiilor multimedia sau de alt tip de pe alte site-uri să fie incluse într-un anumit site pentru a
          crea o experiență de navigare mai valoroasă, mai utilă și mai plăcută;
        </li>
        <li>Îmbunătățesc eficiența publicității online.</li>
      </ul>
      <Headline variant="headline-4" additionalClass="my-16">
        Ce este un „cookie”?
      </Headline>
      <p>
        Un cookie este un fișier text de mici dimensiuni, care se stocheaza pe computerul, terminalul mobil sau alte
        echipamente ale unui utilizator de pe care se accesează Internetul.
      </p>
      <p>
        Cookie-ul este complet „pasiv” (nu conține programe software, viruși sau spyware și nu poate accesa informațiile
        de pe hard driveul utilizatorului).
      </p>
      <p>
        În sine, cookie-urile nu solicită informații cu caracter personal pentru a putea fi utilizate și, în cele mai
        multe cazuri, nu identifica personal utilizatorii de Internet.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Cookie-uri folosite
      </Headline>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              <strong>Cookie</strong>
            </td>
            <td>
              <strong>La ce foloseste?</strong>
            </td>
          </tr>
          <tr>
            <td> _ga</td>
            <td>Google Analytics</td>
          </tr>
          <tr>
            <td>_gat_gtag_UA_S2GR0ENG61</td>
            <td>Google Tag</td>
          </tr>
          <tr>
            <td> _gid</td>
            <td>Google Analytics</td>
          </tr>
          <tr>
            <td>gatsby-gdpr-google-tagmanager</td>
            <td>Confirmare acceptare Cookie</td>
          </tr>
        </tbody>
      </table>
      <Headline variant="headline-4" additionalClass="my-24 mb-16">
        Categorii principale de cookie-uri
      </Headline>
      <p>Există 2 categorii mari de cookie-uri:</p>
      <ul>
        <li>
          Cookieuri de sesiune – acestea sunt stocate temporar în dosarul de cookie-uri al browserului web pentru ca
          acesta să le memoreze până când utilizatorul iese de pe web-site-ul respectiv sau închide fereastra
          browserului (ex: în momentul logării/delogării pe un cont de mail sau pe rețelele de socializare).
        </li>
        <li>
          Cookie-uri persistente – acestea sunt stocate pe hard-drive-ul unui computer sau echipament (în general,
          depinde de durata de viață prestabilită pentru cookie). Cookie-urile persistente le includ și pe cele plasate
          de un alt web-site decât cel pe care îl vizitează utilizatorul la momentul respectiv – sunt cunoscute sub
          numele de ‘third party cookies’ (cookieuri plasate de terți) – care pot fi folosite în mod anonim pentru a
          memora interesele unui utilizator, astfel încât să fie livrată publicitate cât mai relevantă pentru
          utilizatori.
        </li>
      </ul>
      <Headline variant="headline-4" additionalClass="my-16">
        Care sunt avantajele cookie-urilor
      </Headline>
      <p>
        Un cookie conține informații care fac legătura între un web-browser (utilizatorul) și un web-server anume
        (website-ul). Dacă un utilizator accesează acel website din nou, acesta poate citi informația deja stocată și
        reacționa în consecință. Cookie-urile asigură userilor o experiență plăcută de navigare și susțin eforturile
        multor web-site-uri pentru a oferi servicii confortabile utilizatorillor (ex: preferințele în materie de
        confidențialitate online, opțiunile privind limba site-ului, coșuri de cumpărături sau publicitate relevantă).
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Care este durata de viață a unui cookie
      </Headline>
      <p>
        Cookie-urile sunt administrate de web-servere. Durata de viață a unui cookie poate varia semnificativ, depinzând
        de scopul pentru care este plasat. Unele cookie-uri sunt folosite exclusiv pentru o singură sesiune (session
        cookies) și nu mai sunt reținute odată ce utilizatorul a părăsit web-site-ul și unele cookie-uri sunt reținute
        și refolosite de fiecare dată când utilizatorul revine pe acel website („cookie-uri permanente”). Cu toate
        aceste, cookie-urile pot fi șterse de un utilizator în orice moment prin intermediul setărilor browserului.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Ce sunt cookie-urile plasate de terti
      </Headline>
      <p>
        Anumite sectiuni de continut de pe unele site-uri pot fi furnizate prin intermediul unor terte parti/furnizori
        (ex: un video sau o reclama). Aceste terte parti pot plasa de asemenea cookieuri prin intermediul site-ului si
        ele se numesc „third party cookies” pentru ca nu sunt plasate de proprietarul website-ului respectiv. Furnizorii
        terti trebuie sa respecte de asemenea legea in vigoare si politicile de confidentialitate ale detinatorului
        site-ului.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Cum sunt folosite cookie-urile de către acest site
      </Headline>
      <p>O vizită pe acest site poate plasa cookie-uri în scopuri de:</p>
      <ul>
        <li>Cookie-uri de performanță a site-ului;</li>
        <li>Cookie-uri de analiză a vizitatorilor;</li>
        <li>Cookie-uri pentru geolocating;</li>
      </ul>
      <p>
        <strong>Cookie-uri de performanță</strong>
        <br />
        Acest tip de cookie reține preferințele utilizatorului pe acest site, așa încât nu mai este nevoie de setarea
        lor la fiecare vizitare a site-ului.
        <br />
        Exemple:
      </p>
      <ul>
        <li>setările volumului pentru video player;</li>
        <li>viteza de video streaming cu care este compatibil browserul.</li>
      </ul>
      <p>
        <strong>Cookie-uri pentru analiza vizitatorilor</strong>
        <br />
        De fiecare dată când un utilizator vizitează acest site softul de analytics furnizat de o terță parte, generează
        un cookie de analiză a utilizatorului. Acest cookie ne spune dacă ați mai vizitat acest site până acum.
        Browserul ne va spune dacă aveți acest cookie, iar dacă nu, vom genera unul. Acesta permite monitorizarea
        utilizatorilor unici care ne vizitează și cât de des o fac. Atât timp cât nu sunteți înregistrat pe acest site,
        acest cookie nu poate fi folosit pentru a identifica persoanele fizice, ele sunt folosite doar în scop
        statistic.
      </p>
      <p>
        <strong>Cookie-uri pentru geolocating</strong>
        <br />
        Aceste cookie-uri sunt utilizate de către un soft care stabilește din ce țară proveniți. Este complet anonim și
        este folosit doar pentru a înţelege provenienţa / locaţia vizionărilor.
      </p>
      <p>
        <strong>Alte cookie-uri ale terțelor părți</strong>
        <br />
        Pe unele pagini, terții pot seta propriile cookie-uri anonime, în scopul de a urmări succesul unei aplicații sau
        pentru a customiza o aplicație. Datorită modului de utilizare, acest site nu poate accesa aceste cookie-uri, la
        fel cum terțele părți nu pot accesa cookie-urile deținute de acest site. De exemplu, când distribuiți un articol
        folosind butonul pentru rețelele sociale aflat pe acest site, acea rețea socială va înregistra activitatea dvs.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        De ce sunt cookie-urile importante pentru Internet?
      </Headline>
      <p>
        Cookie-urile reprezintă punctul central al funcționării eficiente a Internetului, ajutând la generarea unei
        experiențe de navigare prietenoase și adaptată preferințelor și intereselor fiecărui utilizator. Refuzarea sau
        dezactivarea cookie-urilor poate face unele site-uri imposibil de folosit. Refuzarea sau dezactivarea
        cookie-urilor nu înseamnă că nu veți mai primi publicitate online – ci doar că aceasta nu va mai putea ține cont
        de preferințele și interesele dvs. evidențiate prin comportamentul de navigare caz in care cel mai probabil
        publicitatea pe care o veti primi va fi mai putin relevanta pentru d-voastra.
      </p>
      <p>
        Exemple de întrebuințări importante ale cookie-urilor (care nu necesită autentificarea unui utilizator prin
        intermediul unui cont):
      </p>
      <ul>
        <li>
          conținut și servicii adaptate preferințelor utilizatorului – categorii de știri, vreme, sport, hărți, servicii
          publice și guvernamentale, site-uri de entertainment și servicii de travel.
        </li>
        <li>
          oferte adaptate pe interesele utilizatorilor – reținerea parolelor, preferințele de limbă (ex: afișarea
          rezultatelor căutărilor în limba Română).
          <br />
          Reținerea filtrelor de protecție a copiilor privind conținutul pe Internet (opțiuni family mode, funcții de
          safe search).
        </li>
        <li>
          limitarea frecvenței de difuzare a reclamelor – limitarea numărului de afișări a unei reclame pentru un anumit
          utilizator pe un site.
        </li>
        <li>furnizarea de publicitate mai relevantă pentru utilizator.</li>
        <li>
          măsurarea, optimizarea și caracteristicile de analytics – cum ar fi confirmarea unui anumit nivel de trafic pe
          un website, ce tip de conținut este vizualizat și modul cum un utilizator ajunge pe un website (ex: prin
          motoare de căutare, direct, din alte website-uri etc). Website-urile derulează aceste analize a utilizării lor
          pentru a îmbunătăți site-urile în beneficiul userilor.
        </li>
        <li>securitate și probleme legate de confidențialitate.</li>
      </ul>
      <p>
        Cookieurile nu sunt viruși! Ele folosesc formate tip plain text. Nu sunt alcătuite din bucăți de cod așa că nu
        pot fi executate nici nu pot auto-rula. În consecință, nu se pot duplica sau replica pe alte rețele pentru a se
        rula din nou. Deoarece nu pot îndeplini aceste funcții, nu pot fi considerate viruși. Cookie-urile pot fi totuși
        folosite pentru scopuri negative. Deoarece stochează informații despre preferințele și istoricul de navigare al
        utilizatorilor, atât pe un anume site cât și pe mai multe alte site-uri, cookie-urile pot fi folosite ca o formă
        de Spyware. Multe produse anti-spyware sunt conștiente de acest fapt și în mod constant marchează cookie-urile
        pentru a fi șterse în cadrul procedurilor de ștergere/scanare antivirus/anti-spyware.
      </p>
      <p>
        În general, browserele au integrate setări de confidențialitate care furnizează diferite nivele de acceptare ale
        cookie-urilor: perioada de valabilitate și ștergere automată după ce utilizatorul a vizitat un anumit site.
        Deoarece protecția identității este foarte valoroasă și reprezintă dreptul fiecărui utilizator de Internet, este
        indicat să se știe ce eventuale probleme pot crea cookie-urile. Pentru că prin intermediul lor se transmit în
        mod constant, în ambele sensuri, informații între browser și website, dacă un atacator sau o persoană
        neautorizată intervine în parcursul de transmitere a datelor, informațiile conținute de cookie pot fi
        interceptate. Deși foarte rar, acest lucru se poate întâmpla dacă browserul se conectează la server folosind o
        rețea necriptată (ex: o rețea Wi-Fi nesecurizată).
      </p>
      <p>
        Alte atacuri bazate pe cookie implică setări greșite ale cookie-urilor pe servere. Dacă un website nu solicită
        browserului să folosească doar canale criptate, atacatorii pot folosi această vulnerabilitate pentru a păcăli
        browserele în a trimite informații prin intermediul canalelor nesecurizate. Atacatorii utilizează apoi
        informațiile în scopuri de a accesa neautorizat anumite site-uri. Este foarte important să fiți atenți în
        alegerea metodei celei mai potrivite de protecție a informațiilor personale.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Sfaturi pentru o navigare sigură și responsabilă, bazată pe cookies
      </Headline>
      <p>
        Datorită flexibilității lor și a faptului că majoritatea dintre cele mai vizitate site-uri și cele mai mari
        folosesc cookie-uri, acestea sunt aproape inevitabile. Dezactivarea cookie-urilor nu va permite accesul
        utilizatorului pe site-urile cele mai răspândite și utilizate, printre care: Youtube, Gmail, Yahoo și altele.
      </p>
      <p>Iată câteva sfaturi care vă pot asigura că navigați fără griji, cu ajutorul cookie-urilor:</p>
      <ul>
        <li>
          particularizați-vă setările browserului în ceea ce privește cookie-urile, pentru a reflecta un nivel
          confortabil pentru voi al securității utilizării cookie-urilor.
        </li>
        <li>
          dacă nu vă deranjează cookie-urile și sunteți singura persoană care utilizează computerul, puteăi seta termene
          lungi de expirare pentru stocarea istoricului de navigare și al datelor personale de acces.
        </li>
        <li>
          dacă împărțiți accesul la calculator, puteți lua în considerare setarea browserului pentru a șterge datele
          individuale de navigare de fiecare dată când închideți browserul. Aceasta este o variantă de a accesa
          site-urile care plasează cookie-uri și de a șterge orice informație de vizitare la închiderea sesiunii de
          navigare.
        </li>
        <li>&nbsp;instalați-vă și updatați-vă constant aplicații antispyware.</li>
      </ul>
      <p>
        Multe dintre aplicațiile de detectare și prevenire a spyware-ului includ detectarea atacurilor pe site-uri.
        Astfel, împiedică browserul de la a accesa website-uri care ar putea să exploateze vulnerabilitățile browserului
        sau să descarce software periculos. Asigurați-vă că aveți browserul mereu updatat. Multe dintre atacurile bazate
        pe cookies se realizează exploatând punctele slabe ale versiunilor vechi ale browserelor.
      </p>
      <p>
        Cookie-urile sunt pretutindeni și nu pot fi evitate dacă doriți să vă bucurați de acces pe cele mai bune și cele
        mai mari site-uri de pe Internet – locale sau internaționale. Cu o ințelegere clară a modului lor de operare și
        a beneficiilor pe care le aduc, puteți lua măsurile necesare de securitate astel încât să puteți naviga cu
        încredere pe Internet.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Cum pot opri cookie-urile?
      </Headline>
      <p>
        Dezactivarea și refuzul de a primi cookie-uri pot face anumite site-uri impracticabile sau dificil de vizitat și
        folosit. De asemenea, refuzul de a accepta cookie-uri nu înseamnă că nu veți mai primi/vedea publicitate online.
        Este posibilă setarea din browser, pentru ca aceste cookie-uri să nu mai fie acceptate sau poți seta browserul
        să accepte cookie-uri de la un site anume. De exemplu, daca nu ești înregistat folosind cookie-urile, nu vei
        putea lăsa comentarii. Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. De
        regulă, aceste setări se găsesc, în „opțiuni” sau în meniul de „preferințe” al browserului tău.
      </p>
      <p>
        Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare, altfel puteți folosi opțiunea „ajutor”
        a browserului pentru mai multe detalii.
      </p>
      <ul>
        <li>
          cookie settings în&nbsp;
          <a
            href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="noopener"
          >
            Internet Explorer
          </a>
        </li>
        <li>
          cookie settings în&nbsp;
          <a
            href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
            target="_blank"
            rel="noopener"
          >
            Firefox
          </a>
        </li>
        <li>
          cookie settings în&nbsp;
          <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener">
            Chrome
          </a>
        </li>
        <li>
          cookie settings în&nbsp;
          <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac" target="_blank" rel="noopener">
            Safari
          </a>
        </li>
        <li>
          pentru setările cookie-urilor generate de terți, puteți consulta și site-ul:&nbsp;
          <a href="http://www.youronlinechoices.com/ro/" target="_blank" rel="noopener">
            http://www.youronlinechoices.com/ro/
          </a>
        </li>
      </ul>
      <Headline variant="headline-6" additionalClass="my-16">
        Informaţii suplimentare pot fi consultate în urmatoarele documente şi surse de informare publică:
      </Headline>
      <ul>
        <li>
          <a
            href="http://www.ico.gov.uk/for_organisations/privacy_and_electronic_communications/the_guide/cookies.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            UK Information Commissioner’s Office – “Guidance on the rule of use of cookies and similar technologies”,
            May 2012
          </a>
        </li>
        <li>
          <a
            href="http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=DD:13:36:32002L0058:RO:PDF"
            target="_blank"
            rel="noopener noreferrer"
          >
            Directiva 2002/58/CE privind prelucrarea datelor personale și protejarea confidențialității în sectorul
            comunicațiilor electronice (PDF)
          </a>
        </li>
        <li>
          <a
            href="http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2009:337:0011:0036:RO:PDF"
            target="_blank"
            rel="noopener noreferrer"
          >
            Directiva 2009/136/CE de modificare a Directivei 2002/22/CE privind serviciul universal și drepturile
            utilizatorilor cu privire la reţelele și serviciile de comunicaţii electronice, a Directivei 2002/58/CE
            privind prelucrarea datelor personale și protejarea confidenţialităţii în sectorul comunicaţiilor publice și
            a Regulamentului (CE) nr. 2006/ 2004 privind cooperarea dintre autorităţile naţionale însărcinate să asigure
            aplicarea legislaţiei în materie de protecţie a consumatorului (PDF)
          </a>
        </li>
        <li>
          <a
            href="http://www.legi-internet.ro/legislatie-itc/date-cu-caracter-personal/legea-privind-prelucrarea-datelor-cu-caracter-personal-si-protectia-vietii-private-in-sectorul-comunicatiilor-electronice.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Legea nr.506/2004 privind prelucrarea datelor cu caracter personal și protecția vieții private în sectorul
            comunicațiilor electronice, cu modificările și completările ulterioare
          </a>
        </li>
        <li>
          <a
            href="http://ec.europa.eu/justice/data-protection/article-29/documentation/opinion-recommendation/files/2012/wp194_en.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Avizul nr.4/2012 al Grupului de Lucru Articolul 29 privind cookie-urile exceptate de la obținerea acordului,
            iunie 2012 (PDF)
          </a>
        </li>
        <li>
          <a
            href="http://iab-romania.ro/noutati/activitate-iab/recomandarile-iab-privind-prezenta-cookie-urilor-pe-siteurile-web/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Recomandări IAB România privind informarea utilizatorilor cu privire la utilizarea cookie-urilor pe un site
            web
          </a>
        </li>
        <li>
          <a href="http://www.w3.org/TR/tracking-dnt/" target="_blank" rel="noopener noreferrer">
            World Wide Web Consortium, Tracking Preferences Expression (DNT), W3C Working Draft, 2 Octombrie 2012
          </a>
        </li>
        <li>
          <a href="https://httpsnow.org/help/securecookies" target="_blank" rel="noopener noreferrer">
            Secure Cookies
          </a>
        </li>
        <li>
          <a href="http://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noopener noreferrer">
            Wikipedia – HTTP Cookie
          </a>
        </li>
      </ul>
      <Headline variant="headline-4" additionalClass="my-16">
        Politică de confidențialitate
      </Headline>
      <p>
        Prezenta politică de confidențialitate vă oferă detaliile privind colectarea și procesarea datelor personale
        prin utilizarea site-ului, inclusiv orice informații pe care le puteți oferi prin site-ul nostru.
      </p>
      <p>
        Prin furnizarea datelor dvs. către noi, ne garantați că aveți peste 16 ani. În cazul în care aveți mai puțin de
        16 ani, vă rugăm sa fim contactați de către părinți.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Ce date colectăm despre dvs.
      </Headline>
      <p>
        Date cu caracter personal înseamnă orice informații care pot identifica o persoană. Acestea nu includ date
        anonime.
      </p>
      <p>Putem procesa anumite tipuri de date personale despre dvs. astfel:</p>
      <ul>
        <li>
          <strong>Datele de identitate</strong>&nbsp;pot include nume, prenumele, numele de utilizator.
        </li>
        <li>
          <strong>Datele tehnice&nbsp;</strong>pot include datele cu care va conectați, adresele de protocol internet,
          tipul și versiunea de browser, tipuri și versiuni de browser plug-in, setarea fusului orar și locație, sistem
          de operare și platformă și alte tehnologii pe dispozitivele pe care le utilizați pentru a accesa acest site.
        </li>
        <li>
          <strong>Datele de utilizare&nbsp;</strong>pot include informații cum să folosiți pagina noastră web de
          serviciile.
        </li>
      </ul>
      <Headline variant="headline-4" additionalClass="my-16">
        Date sensibile
      </Headline>
      <p>
        Nu colectăm nicio dată sensibilă despre dvs. Datele sensibile se referă la date care includ detalii despre rasa
        sau etnia dvs., convingeri religioase sau filosofice, viața sexuală, orientarea sexuală, opinii politice,
        apartenența la sindicate, informații despre sănătate dvs. și date genetice și biometrice. Nu colectăm nicio
        informație despre convingeri și infracțiuni penale.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        <div>Scopurile și temeiurile legale ale prelucrării --</div>
        <div>Categoriile de date cu caracter personal colectate și prelucrate</div>
      </Headline>
      <p>
        Prin completarea oricărui formular pus la dispoziție pe site-ul web (spre ex. formular de contact), vă exprimați
        acordul expres cu privire la colectarea și prelucrarea datelor cu caracter personal (nume, adresă de e-mail,
        număr de telefon, sau oricare alte informații care ne oferiți) în scopul de a fi contactat cu privire la
        solicitarea dumneavoastră, în conformitate cu prevederile Regulamentul General privind Protecția Datelor.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Protecția datelor cu caracter personal
      </Headline>
      <p>
        Cabinet de Avocat "Szilagyi Kinga" nu va dezvalui datele cu caracter personal colectate, cu excepția avocaților
        colaboratori autorizați, cărora aceste date le sunt necesare pentru a le procesa în numele Cabinet de Avocat
        "Szilagyi Kinga" sau pentru a furniza servicii disponibile site-ului "law.szilagykinga.com", ori în cazul în
        care obligația de dezvăluire reprezintă o obligație legală a Cabinet de Avocat ”Szilagyi Kinga”, a avocaților
        colaboratori sau a persoanelor împuternicite.
      </p>
      <p>
        Datele cu caracter personal ale utilizatorilor site-urilor Cabinet de Avocat ”Szilagyi Kinga” sunt reținute pe o
        perioada cuprinsă între 1 si 3 ani, utilizatorii fiind informați de fiecare dată când furnizează datele lor
        personale cu privire la perioada exactă de retenție.
      </p>
      <Headline variant="headline-4" additionalClass="my-16">
        Cum colectăm datele dvs. cu caracter personal
      </Headline>
      <p>Colectăm datele despre dvs. printr-o varietate de metode inclusiv:</p>
      <ul>
        <li>
          <strong>Tehnologii sau interacțiuni automate:&nbsp;</strong>Pe măsură ce folosiți site-ul nostru, putem să
          colectăm automat Date tehnice despre echipamentele dvs., acțiunile de navigare și modelele de utilizare.
          Colectăm aceste date prin utilizarea de fișiere cookie, server logs și tehnologii similare. Putem de asemenea
          să primim Date Tehnice despre dvs. dacă vizitați alte pagini web care folosesc cookie-urile noastre. Vă rugăm
          să citiți politica noastră privind fișierele cookie
          la&nbsp;https://www.cabinetdeavocat.eu/politica-privind-cookies/&nbsp;&nbsp;pentru informații suplimentare.
        </li>
        <li>
          <strong>Surse terțe sau disponibile public:&nbsp;</strong>Putem primi date cu caracter personal despre dvs.
          din diverse surse terțe si/sau publice astfel cum sunt stabilite mai jos:
          <ol>
            <li>
              Furnizori de analitică tip&nbsp;<strong>Google</strong>,&nbsp;<strong>Facebook</strong>, din afara UE;
            </li>
            <li>
              Date de identitate sau de contact din surse disponibile public cum sunt Registrul Comerțului cu sediul în
              UE.
            </li>
          </ol>
        </li>
      </ul>
      <p>
        Cabinet de Avocat ”Szilagyi Kinga”, în calitate de operator de date cu caracter personal, a implementat măsurile
        tehnice și organizatorice pentru a asigura respectarea drepturilor utilizatorilor site-urilor (persoane vizate
        conform Regulamentului General privind Protecția Datelor), și anume:
      </p>
      <ul>
        <li>
          <strong>Dreptul de acces&nbsp;</strong>înseamnă dreptul persoanei vizate de a obține o confirmare din partea
          operatorului că prelucrează sau nu datele cu caracter personal care îl privesc și în caz afirmativ, acces la
          datele respective și la informatii privind modalitatea în care sunt prelucrate datele.
        </li>
        <li>
          <strong>Dreptul la portabilitatea datelor&nbsp;</strong>se referă la dreptul de a primi datele personale
          într-un format structurat, utilizat în mod curent și care poate fi citit automat și la dreptul ca aceste date
          să fie transmise direct altui operator, dacă acest lucru este fezabil din punct de vedere tehnic.
        </li>
        <li>
          <strong>Dreptul la opozi</strong>
          <strong>ț</strong>
          <strong>ie&nbsp;</strong>vizează dreptul persoanei vizate de a se opune prelucrării datelor personale atunci
          când prelucrarea este necesară pentru îndeplinirea unei sarcini care servește unui interes public sau când are
          in vedere un interes legitim al operatorului.
        </li>
        <li>
          <strong>Dreptul la rectificare&nbsp;</strong>se referă la corectarea, fără întârzieri nejustificate, a datelor
          cu caracter personal inexacte stocate. Rectificarea trebuie comunicată fiecărui destinatar la care au fost
          transmise datele, cu excepția cazului în care acest lucru se dovedește imposibil sau presupune eforturi
          disproporț
        </li>
        <li>
          <strong>Dreptul la&nbsp;</strong>
          <strong>ș</strong>
          <strong>tergerea datelor (“dreptul de a fi uitat”)&nbsp;</strong>înseamna dreptul persoanei vizate de a
          solicita sa îi fie șterse datele cu caracter personal, fără întârzieri nejustificate, în cazul în care se
          aplică unul dintre următoarele motive: acestea nu mai sunt necesare pentru îndeplinirea scopurilor pentru care
          au fost colectate sau prelucrate; își retrage consimtamantul și nu există niciun alt temei juridic pentru
          prelucrare; se opune prelucrării și nu există motive legitime care să prevaleze; datele cu caracter personal
          au fost prelucrate ilegal; datele cu caracter personal trebuie șterse pentru respectarea unei obligatii
          legale; datele cu caracter personal au fost colectate în legătura cu oferirea de servicii ale societății
          informaț
        </li>
        <li>
          <strong>Dreptul la restric</strong>
          <strong>ț</strong>
          <strong>ionarea prelucrării&nbsp;</strong>poate fi exercitat în cazul în care persoana contestă exactitatea
          datelor, pe o perioada care permite verificarea corectitudinii datelor; prelucrarea este ilegală, iar persoana
          se opune ștergerii datelor cu caracter personal, solicitând în schimb restricționarea; în cazul în care
          operatorul nu mai are nevoie de datele cu caracter personal în scopul prelucrării, dar persoana i le solicită
          pentru constatarea, exercitarea sau apărarea unui drept în instanță; în cazul în care persoana s-a opus
          prelucrării pentru intervalul de timp în care se verifică dacă drepturile legitime ale operatorului prevalează
          asupra celor ale persoanei respective.
        </li>
      </ul>
      <Headline variant="headline-4" additionalClass="my-16">
        Informații privind autoritatea națională pentru supravegherea prelucrării datelor cu caracter personal (ANSPDCP)
      </Headline>
      <p>
        În situația în care considerați că drepturile dumneavoastră prevăzute în Regulamentul General privind Potecția
        Datelor au fost încălcate, aveți posibilitatea de a vă adresa ANSPDCP prin depunerea unei plângeri.
      </p>
      <p>Datele de contact ale ANSPDCP sunt următoarele:</p>
      <p>Adresa: București, B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336</p>
      <p>Telefon: +40.318.059.211/+40.318.059.212</p>
      <p>Fax: +40.318.059.602</p>
      <p>E-mail: anspdcp@dataprotection.ro</p>
      <p>Pagină de internet: www.dataprotection.ro</p>
    </Container>
  );
};

export default CookiePolicy;
