import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import CookiePolicy from 'components/CookiePolicy';
import { graphql } from 'gatsby';

const CookiePolicyPage: React.FC = () => (
  <Layout id="services">
    <SEO title="CookiePolicy" />
    <CookiePolicy />
  </Layout>
);

export default CookiePolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
